import { Button, Card, Col, Form, Icon, Input, message, Row, Select } from 'antd';
import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import styled from 'styled-components';
import SearchDiagnoses from '../../../../../GraphQL/Diagnoses';
import { formatICD10Code } from '../../../../../helpers';
import AddInfoQuestionnaire from '../components/AddInfoQuestionnaire';
import ReferralUploadDrawer from '../components/ReferralUploadDrawer';
import SearchHeaderPatientCard from '../components/SearchHeaderPatientCard';
import moment from 'moment';

const Option = Select.Option;
const { TextArea } = Input;

const Styles = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;

  .headerSection {
    width: 100%;
    .ant-divider {
      margin-top: 0;
    }
  }

  .ant-form-item-label {
    white-space: normal;
    text-align: left;
    line-height: 1rem;
  }

  .main-card {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
`;

const AddInfoStep = ({
  prev,
  next,
  client,
  selectedTimeSlot: { profile, timeSlot },
  sendToPatient,
  sendToProvider,
  setFormValues,
  formValues,
  form,
  getReferral,
  currentOrganization,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [diagnosesOptions, setDiagnosesOptions] = useState([]);
  const [fieldsInitialized, setFieldsInitialized] = useState(false);
  const {
    procedure: {
      specialty: { isOrderRequired, isDiagnosisRequired },
    },
  } = getReferral;
  const { getFieldDecorator } = form;

  const diagnosisRequired = profile
    ? isDiagnosisRequired || profile.organization.referralSettings.isDiagnosisRequired
    : false;
  const noteRequired = profile ? profile.organization.referralSettings.isNoteRequired : false;

  const searchDiagnoses = async filter => {
    const {
      data: { searchDiagnoses: results },
    } = await client.query({ query: SearchDiagnoses, variables: { filter } });

    setDiagnosesOptions(results);
    setIsLoading(false);
  };

  useEffect(() => {
    if (fieldsInitialized) {
      form.setFieldsValue(formValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsInitialized]);

  useEffect(() => {
    setFieldsInitialized(true);
  }, []);

  if (!currentOrganization.referralSettings) {
    return <></>;
  }

  // this overrides the isOrderRequired field on purpose
  const showOrder = !currentOrganization.referralSettings.hideReferralOrder;

  const shoveAnswersIntoNotes = values => {
    // Hack to shove the question answers into the notes field until there is a
    // dedicated api for it.
    const questionnaireName = values.questionnaireName;
    const questionnaireAnswerKeys = values.questionnaireAnswerKeys[0];
    const questionnaire = values.questionnaire[0];
    const notes = values.notes || '';
    const answers = [];
    const questionnaireAnswers = [];

    for (const [questionKey, answer] of Object.entries(questionnaire)) {
      let formattedQuestionKey = questionKey;
      let formattedAnswer = '';

      if (answer === true) formattedAnswer = 'Yes';
      else if (answer === false) formattedAnswer = 'No';
      else if (moment.isMoment(answer)) {
        if (questionKey.endsWith('-date')) {
          formattedQuestionKey = questionKey.replace('-date', '');
          formattedAnswer = answer.format('MMM D, YYYY');
        } else if (questionKey.endsWith('-time')) {
          formattedQuestionKey = questionKey.replace('-time', '');
          formattedAnswer = answer.format('hh:mm A');
        }
      } else {
        formattedAnswer = answer?.toString() || '';
      }

      answers.push(`${formattedQuestionKey}: ${formattedAnswer}`);

      const answerKey = questionnaireAnswerKeys[formattedQuestionKey] || '';

      questionnaireAnswers.push({
        text: formattedQuestionKey,
        value: formattedAnswer,
        key: answerKey,
      });
    }

    const formattedAnswers = answers.join('\n');
    const updatedNotes = `${notes}\n\nQuestionnaire Answers:\n${formattedAnswers}`;
    const answerSets = [{ questionnaireName, questionnaireAnswers }];

    form.setFieldsValue({ notes: updatedNotes, questionnaireAnswerSets: answerSets });
    setFormValues({
      ...values,
      notes: updatedNotes,
      questionnaireAnswerSets: answerSets,
    });
  };

  const handleCheckFormBeforeNext = () => {
    form.validateFields((err, values) => {
      if (err) {
        message.error('Please fix the form errors and try again.');
      } else {
        if (values.questionnaire && values.questionnaire[0]) {
          shoveAnswersIntoNotes(values);
        } else {
          setFormValues(values);
        }
        next();
      }
    });
  };

  const handleDiagnosesSearch = debounce(filter => searchDiagnoses(filter), 500);

  const options = diagnosesOptions.map(d => (
    <Option key={d.id} title={formatICD10Code(d.code)}>
      {formatICD10Code(d.code)} - {d.description}
    </Option>
  ));

  return (
    <Styles>
      <Row type="flex" justify="space-around" gutter={24} style={{ marginBottom: '2rem' }}>
        <Col span={20}>
          <SearchHeaderPatientCard referral={getReferral} />
        </Col>
        <Col span={4}></Col>
      </Row>
      <Row type="flex" justify="center">
        <Col sm={20} md={16} lg={12} xl={10}>
          <Card title="Referral Information" className="main-card">
            {showOrder ? (
              <Form.Item label="Referral Orders">
                <ReferralUploadDrawer
                  referralId={getReferral.id}
                  isRequired={isOrderRequired}
                  form={form}
                  attachmentType="order"
                  hint="Add orders here to attach them to the referral."
                />
              </Form.Item>
            ) : null}
            {timeSlot && timeSlot.serviceType && timeSlot.serviceType[0].coding ? (
              <Form.Item label="Service Type (Required for this schedule)">
                {getFieldDecorator('serviceType', {
                  rules: [{ required: true, message: 'required' }],
                })(
                  <Select
                    placeholder="Select Service Type"
                    defaultActiveFirstOption={false}
                    showArrow={true}
                    filterOption={false}
                    notFoundContent={null}
                  >
                    {timeSlot.serviceType[0].coding.map(c => (
                      <Option key={c.code} value={JSON.stringify({ system: c.system, code: c.code })}>
                        {c.display}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            ) : null}

            <Form.Item label="Diagnosis (ICD-10)">
              {getFieldDecorator('diagnoses', {
                rules: diagnosisRequired ? [{ required: true, message: 'required' }] : [],
                initialValue: form.getFieldValue('diagnoses') || [],
              })(
                <Select
                  mode="multiple"
                  showSearch
                  placeholder="Enter ICD-10 Code"
                  defaultActiveFirstOption={false}
                  showArrow={true}
                  filterOption={false}
                  onSearch={handleDiagnosesSearch}
                  notFoundContent={null}
                  loading={isLoading}
                  dropdownMatchSelectWidth={false}
                >
                  {options}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Notes">
              {getFieldDecorator('notes', {
                rules: noteRequired ? [{ required: true, message: 'required' }] : [],
                initialValue: form.getFieldValue('notes'),
              })(<TextArea rows={4} placeholder="Notes" />)}
            </Form.Item>

            <Form.Item>
              {getFieldDecorator('questionnaireAnswerSets', {})(<Input style={{ display: 'none' }} />)}
            </Form.Item>

            <AddInfoQuestionnaire
              procedureId={getReferral.procedure.id}
              profile={profile}
              form={form}
              referralId={getReferral.id}
            />
            {!currentOrganization?.hideEmailFieldInReferral && (
              <Form.Item label="Email Address">
                {getFieldDecorator('patientEmail', {
                  rules: [
                    { required: sendToPatient, message: 'Required for Send To Patient' },
                    { type: 'email', message: 'A valid email address is required' },
                  ],
                  initialValue: form.getFieldValue('patientEmail') || getReferral.patient.email,
                })(<Input placeholder="Patient Email Address" />)}
              </Form.Item>
            )}
            <Row type="flex" justify="center">
              <Col span={24}>
                <Row type="flex" justify="space-between">
                  <Col>
                    <Button className="prev-button" onClick={() => prev()}>
                      <Icon type="left" /> Back
                    </Button>
                  </Col>
                  <Col>
                    <Button onClick={handleCheckFormBeforeNext}>
                      Next <Icon type="right" />
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Styles>
  );
};

export default withApollo(AddInfoStep);
