import { Tag, Tooltip } from 'antd';
import React, { FC } from 'react';

interface Props {
  profile?: {
    isAccessCenter: boolean;
  };
  status?: string;
}

export const SentToProviderTag: FC<Props> = ({ profile, status }): JSX.Element => {
  if (profile?.isAccessCenter) {
    return (
      <Tooltip title="Referral has been sent to the access / call center" placement="right">
        <Tag color="green">Sent To Access Center</Tag>
      </Tooltip>
    );
  }

  if (status === 'cancelled') {
    return (
      <Tooltip title="Appointment has been cancelled" placement="right">
        <Tag>Cancelled</Tag>
      </Tooltip>
    );
  }

  return (
    <Tooltip title="Appointment information has been sent to provider for scheduling" placement="right">
      <Tag color="green">Sent To Provider</Tag>
    </Tooltip>
  );
};
