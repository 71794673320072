import { Icon, Table, Tag, Tooltip } from 'antd';
import { PaginationConfig, SorterResult } from 'antd/lib/table';
import * as _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import React, { FC, useContext, useState } from 'react';
import { AppContext } from '../../../../modules/core/contexts/AppContext';
import styled from 'styled-components';
import {
  AppointmentInterface,
  EntryInterface,
  ListReferralsInterface,
  PatientInterface,
  ProcedureInterface,
  ProviderInterface,
  EntryTagsInterface,
} from '../../../../GraphQL/ListReferrals.graphql';
import { hasFeature, formatName, FeatureFlag, FeatureGate } from '../../../../helpers';
import { history } from '../../../core/components/App/history';
import {
  QueryParamsInterface,
  QueryVariablesInterface,
  SortFieldEnum,
  SortOrderEnum,
} from '../../pages/ReferralsContainer';
import { SentToProviderTag } from '../SentToProviderTag';
import { ReferralTag } from '../ReferralTag';

export interface TableFiltersInterface {
  procedure?: string[];
  profile?: string[];
  appt_status?: string[];
  referral_status?: string[];
  provider?: string[];
  inserted_at?: string[];
}

interface ColorTagInterface {
  status: string;
}

export const ColorTag: FC<ColorTagInterface> = ({ status }): JSX.Element | null => {
  switch (status) {
    case 'booked':
      return (
        <Tooltip title="Appointment scheduled successfully" placement="right">
          <Tag color="blue">{status}</Tag>
        </Tooltip>
      );
    case 'pending':
      return (
        <Tooltip title="Referral is waiting to be accepted" placement="right">
          <Tag color="orange">{status}</Tag>
        </Tooltip>
      );
    case 'fulfilled':
      return (
        <Tooltip title="Patient has been seen" placement="right">
          <Tag color="green">{status}</Tag>
        </Tooltip>
      );
    case 'noshow':
      return (
        <Tooltip title="Patient failed to show" placement="right">
          <Tag color="red">No Show</Tag>
        </Tooltip>
      );
    case 'failed':
      return (
        <Tooltip title="Referral booking failed" placement="right">
          <Tag color="red">Failed</Tag>
        </Tooltip>
      );
    case 'cancelled':
      return (
        <Tooltip title="Appointment has been cancelled" placement="right">
          <Tag>{status}</Tag>
        </Tooltip>
      );
    case 'proposed':
      return (
        <Tooltip title="Appointment is in progress" placement="right">
          <Tag color="orange">
            Booking
            <Icon type="loading-3-quarters" spin style={{ marginLeft: '5px' }} />
          </Tag>
        </Tooltip>
      );
    case 'completed':
      return (
        <Tooltip title="Referral has been completed" placement="right">
          <Tag>{status}</Tag>
        </Tooltip>
      );
    case undefined:
    case null:
      return null;
    default:
      return <Tag>{status}</Tag>;
  }
};

const Styles = styled.div`
  .main-text {
    font-size: 1em;
  }

  .sub-text {
    font-size: 0.8em;
    overflow-x: hidden;
  }

  .table-row {
    cursor: pointer;

    :nth-child(even) {
      background-color: #eff8ff;
    }
  }

  .ant-tag {
    white-space: nowrap;
  }
`;

interface DataInterface {
  ListReferrals: ListReferralsInterface;
}

interface ReferralsTablePropsInterface {
  organizationId: string;
  data?: DataInterface;
  loading: boolean;
  variables: QueryVariablesInterface | any;
  pageNumber: number;
  totalEntries: number;
  filters: {
    apptStatuses: FiltersInterface[];
    providers: FiltersInterface[];
    profiles: FiltersInterface[];
    referralStatuses: FiltersInterface[];
    procedures: FiltersInterface[];
  };
}

export interface FiltersInterface {
  text: string;
  value: string;
}

const capitalize = (item: string): string => item.charAt(0).toUpperCase() + item.slice(1);
const capitalizeApptStatusFilters = (filters: FiltersInterface[]): FiltersInterface[] => {
  return filters.map(
    ({ text, value }: FiltersInterface): FiltersInterface => {
      if (text === 'noshow') return { text: 'No Show', value: text };
      if (text === 'proposed') return { text: 'Booking', value: text };

      return { text: capitalize(text), value };
    }
  );
};

const capitalizeReferralStatusFilters = (filters: FiltersInterface[]): FiltersInterface[] => {
  return filters.map(
    ({ text, value }: FiltersInterface): FiltersInterface => {
      if (text === 'noshow') return { text: 'No Show', value: text };
      if (text === 'patient_seen') return { text: 'Patient Seen', value: text };

      return { text: capitalize(text), value };
    }
  );
};

type SortOrderType = boolean | 'ascend' | 'descend' | undefined;
type SortOrderInput = 'ASC' | 'DESC' | string | string[] | null | undefined;
type SortColumnType = 'INSERTED_AT' | 'APPT_TIME' | string | string[] | null | undefined;
type ColumnType = 'INSERTED_AT' | 'APPT_TIME';

const getSorterValue = (sortOrder: SortOrderInput, sortColumn: SortColumnType, column: ColumnType): SortOrderType => {
  if (!sortOrder || !sortColumn) return false;
  if (sortColumn !== column) return false;
  if (sortOrder === 'ASC' || sortOrder === 'DESC') {
    if (sortOrder === 'ASC') {
      return 'ascend';
    } else {
      return 'descend';
    }
  }

  return false;
};

export const getFilterValues = (
  filtersQueryString: string | string[] | undefined | null,
  filterName: string
): string[] | undefined => {
  if (!filtersQueryString) return undefined;

  const parsed = Array.isArray(filtersQueryString) ? JSON.parse(filtersQueryString[0]) : JSON.parse(filtersQueryString);

  if (parsed) {
    return parsed[filterName];
  }

  return undefined;
};

const ReferralsTable: FC<ReferralsTablePropsInterface> = ({
  data,
  loading,
  variables,
  filters,
  pageNumber,
  totalEntries,
}): JSX.Element => {
  const entries = data && data.ListReferrals ? data.ListReferrals.entries : [];
  const { filters: queryStringFilters } = queryString.parse(history.location.search);
  const [sortedField, setSortedField] = useState<string | null>(variables.sortField);
  const [sortedOrder, setSortedOrder] = useState<string | null>(variables.sortOrder);
  const { currentOrganization } = useContext(AppContext);
  const hideReferralStatus = hasFeature(currentOrganization?.featureFlags, FeatureFlag.HideReferralStatusColumn);

  const titleWithClassName = (title: string, columnName: string): JSX.Element => {
    return <span className={sortedField === columnName ? 'text-blue' : ''}>{title}</span>;
  };
  const columns = [
    {
      title: titleWithClassName('Created', 'INSERTED_AT'),
      key: 'INSERTED_AT',
      sorter: true,
      render: (referral: EntryInterface): JSX.Element => {
        return (
          <>
            <div className="main-text">
              <Tooltip
                title={moment(moment.utc(referral.insertedAt))
                  .tz(moment.tz.guess())
                  .format('h:mmA z')}
                placement="right"
              >
                {moment(moment.utc(referral.insertedAt))
                  .tz(moment.tz.guess())
                  .format('MM/DD/YYYY')}
              </Tooltip>
            </div>

            <div className="sub-text">{referral.createdByUser.name}</div>
          </>
        );
      },
      sortOrder: getSorterValue(sortedOrder, sortedField, 'INSERTED_AT'),
    },
    ...(!hideReferralStatus
      ? [
          {
            title: 'Referral Status',
            key: 'REFERRAL_STATUSES',
            filters: _.sortBy(
              capitalizeReferralStatusFilters(filters.referralStatuses),
              (item: FiltersInterface) => item.text
            ),
            filteredValue: getFilterValues(queryStringFilters, 'REFERRAL_STATUSES'),
            render: (referral: EntryInterface): JSX.Element => {
              return (
                <div style={{ textTransform: 'capitalize' }}>
                  <ReferralTag status={referral.status} />
                </div>
              );
            },
            sortOrder: getSorterValue(sortedOrder, sortedField, 'INSERTED_AT'),
          },
        ]
      : []),
    {
      title: 'Patient Name',
      key: 'PATIENT_NAME',
      dataIndex: 'patient',
      render: (patient: PatientInterface): JSX.Element => {
        const showMRN = patient.identifier && patient.identifier.length < 36;

        return (
          <>
            <div className="main-text">{formatName(patient)}</div>
            {showMRN && (
              <FeatureGate feature={FeatureFlag.ShowMRN}>
                <div className="sub-text">MRN: {patient.identifier}</div>
              </FeatureGate>
            )}
          </>
        );
      },
    },
    {
      title: 'Referral Type',
      key: 'PROCEDURES',
      dataIndex: 'procedure',
      render: (procedure: ProcedureInterface): JSX.Element => (
        <>
          <div className="main-text">{procedure.specialty.name}</div>
          <div className="sub-text">{procedure.display || procedure.name}</div>
        </>
      ),
      filters: _.sortBy(filters.procedures, (item: FiltersInterface) => item.text),
      filteredValue: getFilterValues(queryStringFilters, 'PROCEDURES'),
    },
    {
      title: 'Sender',
      key: 'PROVIDERS',
      dataIndex: 'provider',
      render: (provider: ProviderInterface): JSX.Element => (
        <>
          <div className="main-text">{provider.name}</div>
          <div className="sub-text">{provider.organization.name}</div>
        </>
      ),
      filters: _.sortBy(filters.providers, (item: FiltersInterface) => item.text),
      filteredValue: getFilterValues(queryStringFilters, 'PROVIDERS'),
    },
    {
      title: 'Receiver',
      key: 'PROFILES',
      render: (referral: EntryInterface): JSX.Element | null => {
        const profile = referral.appointment
          ? referral.appointment.profile
          : referral.patientReferral
          ? referral.patientReferral.profile
          : referral.profileReferral
          ? referral.profileReferral.profile
          : null;

        return profile ? (
          <>
            <div className="main-text">{profile.displayName}</div>
            <div className="sub-text">{profile.organization.name}</div>
          </>
        ) : (
          <div className="main-text">Unlisted Provider</div>
        );
      },
      filters: _.sortBy(filters.profiles, (item: FiltersInterface) => item.text),
      filteredValue: getFilterValues(queryStringFilters, 'PROFILES'),
    },
    {
      title: titleWithClassName('Appt Time', 'APPT_TIME'),
      key: 'APPT_TIME',
      dataIndex: 'appointment',
      sorter: true,
      sortOrder: getSorterValue(sortedOrder, sortedField, 'APPT_TIME'),
      render: (appt: AppointmentInterface): JSX.Element | null => {
        return appt ? (
          <>
            <div className="main-text">{moment(appt.start).format('LL')}</div>
            <div className="sub-text">{moment(appt.start).format('h:mmA')}</div>
          </>
        ) : null;
      },
    },
    {
      title: 'Status',
      key: 'APPT_STATUSES',
      className: 'status-padding',
      filters: _.sortBy(capitalizeApptStatusFilters(filters.apptStatuses), (item: FiltersInterface) => item.text),
      filteredValue: getFilterValues(queryStringFilters, 'APPT_STATUSES'),

      render: (referral: EntryInterface): JSX.Element => {
        const isSentToExternalQueue = (referral: EntryInterface): boolean => {
          const tag = referral.tags?.find((tag: EntryTagsInterface, _index) => {
            return tag.key === 'external_workflow_queue';
          });
          return tag?.value === 'true';
        };

        const tag = (function() {
          if (isSentToExternalQueue(referral)) {
            return (
              <Tooltip title="Referral has been sent to another organization’s workQ" placement="right">
                <Tag color="purple">Sent To External WorkQ</Tag>
              </Tooltip>
            );
          }

          if (!referral.appointment && !referral.profileReferral && !referral.patientReferral) {
            return (
              <Tooltip title="Referral is waiting to be accepted" placement="right">
                <Tag color="orange">Pending</Tag>
              </Tooltip>
            );
          }

          if (!referral.appointment) {
            if (referral.patientReferral) {
              return (
                <div>
                  <Tooltip title="Appointment information has been sent to patient for scheduling" placement="right">
                    <Tag color="orange">Sent To Patient</Tag>
                  </Tooltip>
                  {isStaleSentToPatient(referral) ? <Icon type="warning" style={{ color: '#F6812C' }} /> : null}
                </div>
              );
            } else if (referral.profileReferral) {
              return <SentToProviderTag profile={referral.profileReferral.profile} status={referral.status} />;
            } else if (isSentToExternalQueue(referral)) {
              return (
                <Tooltip title="Referral has been sent to another organization’s workQ" placement="right">
                  <Tag color="purple">Sent To External WorkQ</Tag>
                </Tooltip>
              );
            } else {
              return <SentToProviderTag status={referral.status} />;
            }
          } else {
            let status = referral.appointment.status;
            if (referral.status === 'completed') {
              status = referral.status;
            }
            return <ColorTag status={status} />;
          }
        })();

        const hasAttachment = referral.referralOrder.length > 0;

        return (
          <div style={{ textTransform: 'capitalize', display: 'flex', justifyContent: 'space-between' }}>
            {tag}
            {hasAttachment ? <Icon type="paper-clip" /> : null}
          </div>
        );
      },
    },
  ];

  const getSortOrder = (order: string | null): string | undefined => {
    if (order) {
      return order === 'ascend' ? SortOrderEnum.ASC : SortOrderEnum.DESC;
    }

    return undefined;
  };

  const isStaleSentToPatient = (record: EntryInterface): boolean => {
    if (!record.appointment && record.patientReferral && moment().diff(moment(record.insertedAt), 'days') >= 1) {
      return true;
    }

    return false;
  };

  const handleTableChange = (
    pagination: PaginationConfig,
    filters: TableFiltersInterface,
    sorter: SorterResult<EntryInterface>
  ): void => {
    const queryParams: QueryParamsInterface = queryString.parse(history.location.search);

    const sortOrder = getSortOrder(sorter.order);
    let queryObj: QueryVariablesInterface = {
      locationId: variables.locationId,
      organizationId: variables.organizationId,
      type: variables.type,
      page: pagination.current || 0,
      pageSize: pagination.pageSize,
      sortOrder: sortOrder as SortOrderEnum,
      sortField: sorter.columnKey as SortFieldEnum,
      filters: JSON.stringify(filters),
    };

    if (queryParams.startDate && queryParams.endDate) {
      queryObj = {
        ...queryObj,
        startDate: queryParams.startDate,
        endDate: queryParams.endDate,
        dateRangeText: queryParams.dateRangeText,
      };
    }

    setSortedField(sorter.columnKey as string);
    setSortedOrder(sortOrder as string);
    // no need to refetch since the URL change will trigger the refetch
    history.push(history.location.pathname + '?' + queryString.stringify(queryObj));
  };

  return (
    <Styles>
      <Table<EntryInterface>
        className="referral-table"
        rowClassName={() => 'table-row'}
        sortDirections={['ascend', 'descend', 'ascend']}
        onRow={record => {
          return {
            onClick: event => {
              event.preventDefault();
              history.push(`/referrals/${record.id}`);
            },
          };
        }}
        dataSource={entries}
        columns={columns}
        rowKey={(record: EntryInterface): string => record.id}
        pagination={{
          pageSize: variables.pageSize,
          position: 'bottom',
          showSizeChanger: true,
          defaultCurrent: 0,
          current: pageNumber,
          total: totalEntries,
          showTotal: total => {
            return `${total} items`;
          },
        }}
        loading={loading}
        onChange={handleTableChange}
      />
    </Styles>
  );
};

export default ReferralsTable;
